.container{
    display: flex;
    position: relative;
    top: 0;
    margin: 40% auto;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (min-width: 767px){
    .container {
      margin: 10% auto;
    }
  }